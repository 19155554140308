import React, { useState, FC } from 'react'
import { checkAuth, login } from '../apis/auth'
import useReactRouter from 'use-react-router'
import { useDispatch } from 'react-redux'
import {
  Avatar,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Alert,
  useTheme,
} from '@mui/material'
import axios from 'axios'
import { localStorageWrapper } from '../helpers/localStorage'

type EditMode = {
  kana: boolean
  insurance_num: boolean
  birthday: boolean
  allowed_insurance_num_blank: boolean
}

type User = {
  id: string
  pharmacy_name: string
  plan: string
  qr_size: string
  edit_mode: EditMode
  app_type: string
  is_annotator: boolean
  is_activated_photo: boolean
  show_save_csv_button: boolean
  is_image_rotatable: boolean
  editable_item_font_size: string
  is_audio_upload: boolean
}

type ResponseData = {
  version: null
  user: User
}

export const LoginPage: FC = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errormessage, setErrormessage] = useState<string>('')
  const dispatch = useDispatch()
  const { history } = useReactRouter()
  const theme = useTheme()

  const dispatchIsLoggedIn = () => {
    dispatch({ type: 'setIsLoggedIn', isLoggedIn: true })
  }

  const onSuccess = (responseData: ResponseData) => {
    dispatch({
      type: 'setIsLoggedIn',
      isLoggedIn: true,
      appType: responseData.user.app_type,
    })
    localStorageWrapper.setItem('userId', responseData.user.id)
    localStorageWrapper.setItem(
      'IsAudioUpload',
      responseData.user.is_audio_upload
    )
  }

  const submit = async () => {
    setErrormessage('')
    console.log('hello')
    if (!validate()) return
    try {
      const { data } = await login(email, password)
      console.log(data)
      const response = await checkAuth()
      onSuccess(response.data)
      history.push('/')
    } catch (e) {
      console.log(e)
      if (axios.isAxiosError(e)) {
        if (!e.response) {
          setErrormessage(
            'ネットワークに接続できていないようです。接続を確認して、もう一度お試しください。'
          )
        } else {
          const status = e.response.status
          switch (status) {
            case 400: {
              const remaining_attempts = e.response.data.remaining_attempts
              const cooloff_time = e.response.data.cooloff_time
              setErrormessage(
                `メールアドレスまたはパスワードが正しくない可能性があります。残り${remaining_attempts}回間違えると${cooloff_time}時間ログインできなくなるので、ご注意ください。`
              )
              break
            }
            case 403: {
              setErrormessage(
                '一定回数以上ログインが失敗したため、アカウントへのアクセスが一時的に制限されています。アクセス制限を解除したい場合、または新しい認証情報が必要な場合は、担当者にお問い合わせください。'
              )
              break
            }
            default: {
              setErrormessage(
                '予期しないエラーが発生しました。もう一度お試しください。'
              )
            }
          }
        }
      } else {
        setErrormessage(
          '予期しないエラーが発生しました。もう一度お試しください。'
        )
      }
    }
  }

  const validate = () => {
    if (!email) return false
    if (!password) return false
    return true
  }

  return (
    <Stack
      height="100vh"
      direction="column"
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        <Avatar />
        <Typography
          component="h1"
          variant="h5"
          fontFamily="Hiragino Maru Gothic Pro"
        >
          ログイン
        </Typography>
      </Stack>
      <Box width="400px">
        <form noValidate>
          <Stack direction="column" spacing={3}>
            <Stack direction="column" spacing={1}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Emailアドレス"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{
                  style: { color: theme.palette.secondary.dark },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputLabelProps={{
                  style: { color: theme.palette.secondary.dark },
                }}
              />
            </Stack>
            <Button
              fullWidth
              variant="contained"
              sx={{
                bgcolor: theme.palette.primary.dark,
                color: '#fff',
              }}
              onClick={submit}
            >
              ログイン
            </Button>
          </Stack>
        </form>
        {errormessage && (
          <Box pt={2}>
            <Alert severity="error">{errormessage}</Alert>
          </Box>
        )}
      </Box>
    </Stack>
  )
}
